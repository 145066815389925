.horizontal-nav {
    padding: 5px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%; /* Center within the top 50% of the page */
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 10;
  }
  
  .burger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .burger-menu .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: 0.4s;
  }
  
  .burger-menu .bar.open:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .burger-menu .bar.open:nth-child(2) {
    opacity: 0;
  }
  
  .burger-menu .bar.open:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  .nav-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  
  .nav-links a, .mute-button {
    background: linear-gradient(45deg, #4b6cb7, #182848); /* Chic gradient color */
    color: white;
    text-decoration: none;
    margin: 2px; /* Reduce margin to bring links closer together */
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    transition: transform 0.2s, background-color 0.2s, box-shadow 0.2s, top 0.2s;
    position: relative;
    top: -10px; /* Initial position for animation */
  }
  
  .nav-links a:hover, .mute-button:hover {
    background: linear-gradient(45deg, #3a4a8b, #121a33); /* Darker gradient on hover */
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    top: 0; /* Move down on hover */
  }
  
  @media (max-width: 768px) {
    .horizontal-nav {
      justify-content: flex-start;
      width: 100%;
      padding-left: 10px;
    }
  
    .burger-menu {
      display: flex;
    }
  
    .burger-menu .bar {
      width: 30px; /* Make the bars smaller */
      height: 2px; /* Make the bars thinner */
      margin: 5px 0; /* Adjust margin for smaller bars */
    }
  
    .nav-links {
      display: none;
      flex-direction: row; /* Change to row for horizontal layout */
      align-items: center;
      width: auto;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
      padding: 10px;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  
    .nav-links.open {
      display: flex;
      background-color: transparent; /* Transparent background when open */
    }
  
    .nav-links a, .mute-button {
      width: auto; /* Adjust width as needed */
      margin: 5px;
      padding: 10px 20px; /* Ensure consistent padding */
      text-align: center; /* Center the text */
    }
  }