@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background: #000033; /* Set background to black */
}

.App {
  position: relative;
  z-index: 1;
  color: white; /* Adjust text color for better visibility over the black background */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centered-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0; /* Remove border radius for wall-to-wall effect */
  box-shadow: none; /* Remove box shadow for a cleaner look */
  z-index: 0; /* Ensure background image is behind bubbles */
}

@media (max-width: 768px) {
  .centered-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center top; /* Adjust this value to center on her face */
  }

  .bubble {
    animation: float 20s linear infinite; /* Slower animation for mobile */
  }

  .bubble:nth-child(odd) {
    animation-delay: 15s; /* Less frequent bubbles for mobile */
  }

  .bubble:nth-child(even) {
    animation-delay: 10s; /* Less frequent bubbles for mobile */
  }

  .contract-address {
    top: 10px; /* Position it at the top for mobile */
    transform: translateX(-50%); /* Center it horizontally */
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-120vh);
  }
}

.sparkles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1; /* Ensure sparkles are behind bubbles but in front of the background image */
}

.sparkle {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #007BFF; /* Blue sparkles */
  border-radius: 50%;
  box-shadow: 0 0 10px #007BFF, 0 0 20px #007BFF, 0 0 30px #007BFF; /* Blue glow */
  animation: sparkle 2s infinite;
}

@keyframes sparkle {
  0%, 100% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

.image-preview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-preview img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.contract-address {
  position: absolute;
  top: calc(80% - 1px); /* Position it just above the centered image */
  left: 50%;
  transform: translate(-50%, -100%); /* Center it horizontally and move it up */
  background: rgba(0, 0, 139, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  z-index: 3; /* Ensure the contract address is in front of everything */
  user-select: none;
  font-size: 12px; /* Make the text smaller */
}

.contract-address-bot {
  position: absolute;
  top: calc(28% - 1px); /* Position it just above the centered image */
  left: 50%;
  transform: translate(-50%, -100%); /* Center it horizontally and move it up */
  background: rgba(0, 0, 139, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  z-index: 3; /* Ensure the contract address is in front of everything */
  user-select: none;
  font-size: 12px; /* Make the text smaller */
}

.contract-address-title {
  position: absolute;
  top: calc(32% - 1px); /* Position it just above the centered image */
  left: 50%;
  transform: translate(-50%, -100%); /* Center it horizontally and move it up */
  background: rgba(0, 0, 139, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  z-index: 3; /* Ensure the contract address is in front of everything */
  user-select: none;
  font-size: 32px; /* Make the text bigger */
  text-align: center;
  font-weight: 700; /* Make the text bolder */
  font-family: 'Roboto', sans-serif; /* Ensure the font is Roboto */
  white-space: nowrap; /* Prevent text from wrapping */

}

.contract-address-email {
  position: absolute;
  top: calc(98% - 1px); /* Position it just above the centered image */
  left: 50%;
  transform: translate(-50%, -100%); /* Center it horizontally and move it up */
  background: rgb(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  z-index: 3; /* Ensure the contract address is in front of everything */
  user-select: none;
  font-size: 12px; /* Make the text smaller */
}

.contract-address:hover {
  background: rgba(0, 0, 0, 0.7);
}